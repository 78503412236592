/* eslint-disable react/button-has-type */
import React, {useState} from 'react'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import PageHeader from '../components/Layout/PageHeader'
import Products from '../components/Products'
import {Inner} from '../components/styles'

// This index should rather be generated from already created product pages to avoid missing links
const StoreIndex = ({location}) => {
  const data = useStaticQuery(graphql`
    {
      allWcProductsCategories {
        edges {
          node {
            name
            count
          }
        }
      }
      wp: wordpressPage(wordpress_id: {eq: 175}) {
        title
        content
      }
      seo: wpgraphql {
        pageBy(pageId: 175) {
          seo {
            title
            metaDesc
            focuskw
            metaKeywords
            metaRobotsNoindex
            metaRobotsNofollow
            opengraphTitle
            opengraphDescription
            opengraphImage
            twitterTitle
            twitterDescription
            twitterImage
          }
        }
      }
    }
  `)

  const filters = data.allWcProductsCategories.edges
    .map(edge => {
      if (edge.node.count > 0) {
        return edge.node
      }
      return null
    })
    .filter(valid => valid)
    .sort((first, second) => second.count - first.count)

  const [filter, setFilter] = useState(filters[0])
  const [order, setOrder] = useState('DSC')

  // const filteredProducts = products.filter(p => {
  //   if (!p.node.mainImageHref) return false
  //   const nodeTags = p.node.tags ? p.node.tags : ''
  //   if (p.node.relationships.parent === null && nodeTags.includes('mattress')) {
  //     return true
  //   }
  //   return false
  // })

  return (
    <Layout location={location} background="#F8F8F8" flush>
      <SEO
        location={location}
        seo={data.seo.pageBy.seo}
        title="Kooi Beds Store"
      />
      <PageHeader flush location={location}>
        {data.wp.title}
      </PageHeader>
      <FilterBar>
        <h2 className="red">
          <strong>Product</strong> Range
        </h2>
        <div>
          {filters.map(item => (
            <FilterButton
              active={filter.name === item.name}
              onClick={() => {
                setFilter(item)
              }}
            >
              {item.name}
            </FilterButton>
          ))}
          <OrderButton
            onClick={() => {
              setOrder(order === 'ASC' ? 'DSC' : 'ASC')
            }}
          >
            {order === 'ASC' ? (
              <>
                <Icon name="filter" /> Sort by Lowest Price to Highest
              </>
            ) : (
              <>
                <Icon name="filter" /> Sort by Highest Price to Lowest
              </>
            )}
          </OrderButton>
        </div>
      </FilterBar>
      <Inner>
        <Products filter={filter} order={order} />
      </Inner>
    </Layout>
  )
}

export default StoreIndex

const FilterButton = styled.div`
  padding: 8px 20px;
  border-radius: 3px;
  background: ${({active, theme}) => (active ? theme.redHover : theme.grey4)};
  color: ${({active, theme}) => (active ? theme.white : '')};
  margin: 4px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
`

const OrderButton = styled.div`
  padding: 8px 20px;
  border-radius: 3px;
  background: ${({theme}) => theme.grey2};
  color: ${({theme}) => theme.white};
  margin: 4px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  &:hover {
    transform: translateY(-3px);
  }
`

const FilterBar = styled.div`
  box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.07);
  background: white;
  padding: 40px;
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    flex-wrap: wrap;
  }

  h2 {
    margin-top: 0 !important;
  }
`
